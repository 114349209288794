import { graphql, Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import support from '../assets/images/support.jpg';
import NavPages from '../components/NavPages';
import SEO from '../components/SEO';
import SupportCard from '../components/SupportCard';

const SupportPageStyled = styled.div`
  width: 100%;
  .support-header {
    background-image: linear-gradient(
        to bottom,
        var(--whiteTrans),
        var(--whiteTrans)
      ),
      url(${support});
  }
  .support-container {
    max-width: var(--wrapper);
    width: 100%;
    margin: 4rem auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: inherit;
    justify-items: stretch;
    gap: 2rem;
    @media (max-width: 1023px) {
      grid-template-columns: 1fr;
    }
    .card {
      border-radius: 8px;
      padding: 2rem;
      background: #000;
      color: #fff;
      text-align: center;
    }
  }
`;

export default function SupportPage({ data: { support } }) {
  return (
    <>
      <SEO title="Get support from Bont Rowing" />
      <SupportPageStyled>
        <div className="wrapper">
          <section className="page-header support-header">
            <div>
              <h2>{support.nodes[0].title}</h2>
            </div>
          </section>
          <section className="page-description support-description">
            <p>{support.nodes[0].shortDescription}</p>
          </section>
          <section className="support-container">
            {support.nodes[0].supportPages.map((item) => (
              <SupportCard
                key={item._key}
                title={item.title}
                description={item.description ? item.description : ''}
                backgroundImage={
                  item.featuredImage
                    ? item.featuredImage.asset.fluid.srcWebp
                    : ''
                }
                href={`/support/${item.slug.current}`}
                linkText={item.linkText ? item.linkText : ''}
                colorScheme={item.colorScheme ? item.colorScheme : 'black'}
                slug={item.slug.current}
              />
            ))}
          </section>
        </div>
        <NavPages />
      </SupportPageStyled>
    </>
  );
}

export const query = graphql`
  query {
    support: allSanitySupport {
      nodes {
        title
        shortDescription
        supportPages {
          title
          _key
          slug {
            current
          }
          description
          colorScheme
          href
          linkText
          featuredImage {
            asset {
              fluid(maxWidth: 1000) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  }
`;
